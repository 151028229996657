@import "../styles/const";

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  &_list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex-direction: column;
    height: 84%;
    @media screen and (orientation:landscape) {
      flex-direction: row;
      justify-content: space-between;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
    }
  }
  &_item {
    width: 100%;
    @media screen and (orientation:landscape) {
      width: auto;
    }
    &:nth-child(2) {
      margin-top: calcVerticalVH(100);
      @media screen and (orientation:landscape) {
        width: 50%;
        display: block;
        margin-top: calcHorizontalVH(25);
      }
    }
  }

  &_status {
    display: block;
    font-size: calcVerticalVH($title);
    font-weight: 700;
    height: calcVerticalVH(90);;
    margin: calcVerticalVH(100) 0 calcVerticalVH(40) 0;
    width: 100%;

    @media screen and (orientation:landscape) {
      display: none;
    }
  }
  &_title {
    font-size: calcVerticalVH(40);
    font-weight: 400;
    line-height: calcVerticalVH(60);
    margin: calcVerticalVH(55) 0;

    @media screen and (orientation:landscape) {
      display: none;
    }
  }
  .name {
    font-size: calcVerticalVH(50);
    font-weight: 500;
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;

    @media (min-height: 200px) and (max-height: 500px) and (orientation:landscape) {
      font-size: calcVH(60, 600);
    }
    @media (min-height: 500px) and (max-height: 700px) and (orientation:landscape) {
      font-size: calcVH(70, 900);
    }
    @media (min-height: 700px) and (max-height: 982px) and (orientation:landscape) {
      font-size: calcVH(80, 800);
    }
  }

  .position {
    font-size: calcVerticalVH(40);
    font-weight: 400;
    margin-bottom: calcVerticalVH(10);

    @media (min-height: 200px) and (max-height: 500px) and (orientation:landscape) {
      font-size: calcVH(40, 600);
      margin-bottom: calcVH(10, 600);
    }
    @media (min-height: 500px) and (max-height: 700px) and (orientation:landscape) {
      font-size: calcVH(50, 900);
      margin-bottom: calcVH(10, 900);
    }
    @media (min-height: 700px) and (max-height: 982px) and (orientation:landscape) {
      font-size: calcVH(60, 800);
      margin-bottom: calcVH(10, 800);
    }
  }

  .pronouns {
    font-size: calcVerticalVH(40);
    font-weight: 400;

    @media (min-height: 200px) and (max-height: 500px) and (orientation:landscape) {
      font-size: calcVH(40, 600);
    }
    @media (min-height: 500px) and (max-height: 700px) and (orientation:landscape) {
      font-size: calcVH(50, 900);
    }
    @media (min-height: 700px) and (max-height: 982px) and (orientation:landscape) {
      font-size: calcVH(60, 800);
    }
  }

  &_footer {
    height: 16%;
  }
}

.private {
  font-size: calcVerticalVH(65);
  font-weight: 500;

  @media (min-height: 200px) and (max-height: 500px) and (orientation:landscape) {
    font-size: calcVH(100, 600);
  }
  @media (min-height: 500px) and (max-height: 700px) and (orientation:landscape) {
    font-size: calcVH(100, 900);
  }
  @media (min-height: 700px) and (max-height: 982px) and (orientation:landscape) {
    font-size: calcVH(100, 800);
  }
}

.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;

  @media screen and (orientation:landscape) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .title {
    display: none;
    font-size: calcVerticalVH(40);
    @media screen and (orientation:landscape) {
      display: block;
      font-size: calcHorizontalVH(40);
    }
  }
}
